<template>
  <ToznyForm
    :on-submit="handleSubmit"
    :error="formErrors"
    :loading="loading"
    class="w-full"
    :class="disabled ? 'opacity-25' : false"
  >
    <div :class="inlineClasses" id="create-client-enabled">
      <div class="w-full" :class="inline ? 'flex' : ''">
        <ToznyInput
          data-auto-id="new-client-name"
          v-model="providedName"
          class="flex-grow"
          :class="inlineInputClass"
          :disabled="disabled"
          :float-label="inline"
          name="clientName"
          id="clientName"
          label="Client Name"
          placeholder="Use something descriptive"
        />
        <ToznySelect
          :disabled="disabled"
          :class="inlineInputClass"
          :float-label="inline"
          name="regToken"
          id="regToken"
          data-auto-id="regToken"
          label="Registration Token"
          v-model="registrationToken"
        >
          <option value>Select a Token</option>
          <option
            v-for="(token, index) in tokens"
            v-bind:key="token.token"
            :value="token.token"
            :data-auto-id="`option${index}`"
          >
            {{ token.name }} ({{ token.token }})
          </option>
        </ToznySelect>
      </div>
      <div class="flex flex-row-reverse items-center">
        <ToznyButton
          id="new-client-submit"
          data-auto-id="new-client-submit"
          class="m-0"
          buttonText="Create Client"
          :loading="loading"
          :disabled="disabled"
        />
        <a
          href="#!"
          v-if="!inline"
          @click.prevent="$emit('cancel')"
          class="text-gray-darkest no-underline mr-8"
          >Cancel</a
        >
      </div>
    </div>
  </ToznyForm>
</template>

<script>
import ToznyForm from '../../../Common/ToznyForm'
import ToznyButton from '../../../Common/ToznyButton'
import ToznyInput from '../../../Common/ToznyInput'
import ToznySelect from '@/Common/ToznySelect'
export default {
  name: 'ClientForm',
  components: {
    ToznyForm,
    ToznyButton,
    ToznyInput,
    ToznySelect,
  },
  props: {
    loading: Boolean,
    error: String,
    tokens: Array,
    inline: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
  },
  data() {
    return {
      providedName: '',
      registrationToken: '',
      validationErrors: [],
    }
  },
  computed: {
    inlineClasses() {
      return this.inline ? 'flex justify-center' : ''
    },
    inlineInputClass() {
      return this.inline ? 'mr-4 w-1/2' : 'mb-8'
    },
    regTokenSelected() {
      return !!this.registrationToken
    },
    formErrors() {
      const errors = [...this.validationErrors]
      if (this.error) {
        errors.push(this.error)
      }
      return errors.join(', ')
    },
  },
  methods: {
    handleSubmit() {
      if (this.disabled) {
        return
      }

      const errors = []
      const trimmedName = this.providedName.trim()
      if (trimmedName === '') {
        errors.push('Name can not be empty')
      }
      if (this.registrationToken === '') {
        errors.push('You must select a registration token')
      }
      this.validationErrors = errors
      if (this.validationErrors.length > 0) {
        return
      }

      this.$emit('submit', {
        name: trimmedName,
        registrationToken: this.registrationToken,
      })
    },
  },
}
</script>
<style scoped>
/* TODO: Figure out why tailwinds doesn't work here and add a custom tailwind style for this */
.max-width {
  max-width: 200px;
  min-width: 100px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* background: url(dropdown.ico) right / 20px no-repeat #fff; */
  /* padding-right: 20px; */
}

.dropdown-icon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
}
</style>

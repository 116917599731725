<template>
  <div class="request-history-table px-4">
    <ItemTable
      data-auto-id="request-history-table"
      layout-fixed
      :items="requests"
      :columns="tableColumns"
    >
      <template #path="{ itemData }">
        <span class="font-bold text-tiny lg:text-base">{{ itemData }}</span>
      </template>
      <template #request="{ itemData }">
        <span class="text-tiny text-gray-dark">{{ itemData }}</span>
      </template>
      <template #date="{ itemData }">
        <span class="text-tiny text-gray-dark">{{
          moment(itemData).format('YYYY-MM-DD HH:mm:ss')
        }}</span>
      </template>
    </ItemTable>
    <div v-if="error" id="form-error" class="mt-4 text-error text-tiny mb-4">
      {{ error }}
    </div>
    <a
      href="#!"
      @click.prevent="$emit('loadMore')"
      v-if="hasMore && !loading"
      class="flex flex-col items-center text-gray-dark no-underline hover:text-gray-darkest"
    >
      <div class="text-tiny -mb-2">Load More</div>
      <i class="material-icons-outlined md-24 micon">expand_more</i>
    </a>
    <div v-if="loading" class="text-center">
      <ToznyLoader v-if="loading" class="mt-2 w-8 h-8 text-tozny" />
    </div>
  </div>
</template>

<script>
import ItemTable from '@/Common/ItemTable'
import ToznyLoader from '@/Common/ToznyLoader'
import moment from 'moment'
export default {
  name: 'RequestHistoryTable',
  data: function () {
    return {
      moment: moment,
    }
  },
  props: {
    requests: {
      type: Array,
      default: function () {
        return []
      },
    },
    hasMore: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
  },
  components: {
    ItemTable,
    ToznyLoader,
  },
  computed: {
    tableColumns() {
      return [
        {
          title: 'Path',
          target: 'request_path',
          slot: 'path',
          className: 'w-1/4',
        },
        {
          title: 'Method',
          target: 'request_method',
          slot: 'request',
          className: 'w-1/12',
        },
        {
          title: 'Associated Client ID',
          target: 'client_id',
          slot: 'request',
          className: 'w-1/4',
        },
        {
          title: 'Response',
          target: 'status_code',
          slot: 'request',
          className: 'w-1/12 truncate',
        },
        {
          title: 'Date',
          target: 'request_time',
          slot: 'date',
          className: 'w-1/12',
        },
      ]
    },
  },
}
</script>

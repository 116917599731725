<template>
  <ToznyForm
    :on-submit="handleSubmit"
    :error="formError ? 'Token name required.' : error"
    :loading="loading"
    class="text-left"
  >
    <div :class="inlineClasses">
      <ToznyInput
        data-auto-id="new-token-name"
        v-model="providedName"
        :class="inlineInputClass"
        class="w-full"
        name="tokenName"
        id="tokenName"
        label="Name"
        placeholder="android-app"
        :float-label="inline"
      />
      <div class="flex flex-row-reverse items-center">
        <ToznyButton
          id="create-token-button"
          data-auto-id="new-token-submit"
          class="m-0"
          buttonText="Create Token"
          :loading="loading"
        />
        <a
          href="#!"
          v-if="!inline"
          @click.prevent="$emit('cancel')"
          class="text-gray-darkest no-underline mr-8"
          >Cancel</a
        >
      </div>
    </div>
  </ToznyForm>
</template>

<script>
import ToznyForm from '../../../Common/ToznyForm'
import ToznyButton from '../../../Common/ToznyButton'
import ToznyInput from '../../../Common/ToznyInput'
export default {
  name: 'TokenForm',
  components: {
    ToznyForm,
    ToznyButton,
    ToznyInput,
  },
  props: {
    loading: Boolean,
    error: String,
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      providedName: '',
      formError: false,
    }
  },
  computed: {
    inlineClasses() {
      return this.inline ? 'flex justify-center' : ''
    },
    inlineInputClass() {
      return this.inline ? 'mr-4' : 'mb-8'
    },
  },
  methods: {
    handleSubmit() {
      if (this.providedName) {
        const token = {
          name: this.providedName,
          permissions: {
            enabled: true,
            allowed_types: ['general', 'identity', 'broker'],
          },
        }
        this.$emit('submit', token)
      } else {
        this.formError = true
      }
    },
  },
}
</script>

<style></style>

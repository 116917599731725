<template>
  <div
    data-auto-id="getting-started"
    class="tozny-gradient text-center text-white px-54 py-12 mb-6"
  >
    <p class="mb-0">Welcome to Tozny</p>
    <h1 class="mt-0 mb-4">Getting Started</h1>
    <p class="mb-0 p-5">
      Get started with TozStore or TozId using the menu to your left.
      <br />
      Need help? Reach us anytime using the support chat tool on the bottom
      right or at support@tozny.com
    </p>

    <div class="flex justify-center">
      <div class="mr-4">
        <a
          class="no-underline"
          href="https://developers.tozny.com"
          target="_blank"
        >
          <ToznyButton
            class="tozny-theme-button focus:outline-none active:outline-none bg-transparent text-white border-2 border-white mb-0"
            id="github-link-button"
            buttonText="Developer Center"
            :loading="false"
          />
        </a>
      </div>
      <div>
        <a
          class="no-underline"
          href="https://docs.tozny.com/#introduction"
          target="_blank"
        >
          <ToznyButton
            class="tozny-theme-button focus:outline-none active:outline-none bg-transparent text-white border-2 border-white mb-0"
            id="api-docs-button"
            buttonText="API Docs"
            :loading="false"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ToznyButton from '@/Common/ToznyButton'
export default {
  name: 'GettingStarted',
  components: {
    ToznyButton,
  },
}
</script>

<style scoped lang="scss">
@import '@/scss/variables.scss';

.tozny-theme-button:hover {
  background: $white;
  color: $theme-green;
  border: 2px solid $theme-green-active;
  border-radius: 6px;
}

.tozny-theme-button:active {
  border: 2px solid $theme-green-active;
  border-radius: 6px;
  background-color: $theme-green-active;
  color: $white;
}
</style>

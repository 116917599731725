<template>
  <section data-auto-id="zero-state-landing">
    <GettingStarted />
    <div
      v-if="realmLoading"
      class="w-full flex items-center justify-center"
      id="add-realm-spinner-container"
    >
      <ToznyLoader class="text-tozny w-24 h-24" />
    </div>
    <NoRealms
      v-if="realmStatusZeroOrError"
      :create-realm="addRealm"
      :loading="realmStatus === 'zero.adding'"
      :errorMessage="realmErrorMessage"
    />
    <NoTokens
      v-if="clientsDisabled"
      :create-token="createInitialToken"
      :loading="tokensStatus === 'zero.adding'"
      :errorMessage="tokensError"
    />
    <NoClients
      :disabled="clientsDisabled"
      :loading="clientsStatus === 'zero.adding'"
      :tokens="tokens"
      :errorMessage="clientsError"
      @submit="registerClient"
    />
  </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import GettingStarted from './GettingStarted'
import NoClients from '@/Pages/Clients/Components/NoClients'
import NoRealms from '@/Pages/Identity/Realms/Components/NoRealms'
import NoTokens from '@/Pages/Tokens/Components/NoTokens'
import ToznyLoader from '../../../Common/ToznyLoader'
export default {
  name: 'ZeroStateLanding',
  components: {
    GettingStarted,
    NoClients,
    NoRealms,
    NoTokens,
    ToznyLoader,
  },
  computed: {
    ...mapState('clients', {
      clientsStatus: 'status',
      clientsError: 'errorMessage',
    }),
    ...mapGetters('clients', {
      clientsDisabled: 'disabled',
    }),
    ...mapState('tokens', {
      tokensStatus: 'status',
      tokens: 'tokens',
      tokensError: 'errorMessage',
    }),
    ...mapState('realms', {
      realmStatus: 'status',
      realmErrorMessage: 'errorMessage',
    }),
    realmStatusZeroOrError() {
      return (
        this.realmStatus === 'zero' ||
        this.realmStatus === 'zero.adding' ||
        this.realmStatus === 'add'
      )
    },
  },
  data() {
    return {
      realmLoading: false,
    }
  },
  methods: {
    ...mapActions('clients', ['registerClient']),
    ...mapActions('tokens', ['createToken', 'transitionStatus']),
    ...mapActions('realms', {
      initializeRealms: 'initialize',
      addRealmAction: 'addRealm',
    }),
    async createInitialToken(token) {
      await this.transitionStatus('zero.adding')
      await this.createToken(token)
    },
    async addRealm(realmName) {
      // show the loading spinner
      this.realmLoading = true
      // make the call to create new realm
      await this.addRealmAction(realmName)
      // if no errors redirect to realms
      if ((this.realmErrorMessage || '').length === 0) {
        this.$router.push('/identity/realms')
      }
      // hide loading spinner
      this.realmLoading = false
    },
  },
  /* The user will likely have scrolled down while viewing the ZeroStateLanding component.
      This lifecycle hook ensures the main dashboard components appear with the scrollbar at the top.
      Otherwise the user will have to scroll up, and this may be disorienting.  x
  */
  beforeDestroy: function () {
    window.scroll({
      top: 100,
    })
  },
  created: async function () {
    await this.initializeRealms()
  },
}
</script>
<style scoped lang="scss">
#add-realm-spinner-container {
  margin-top: 280px;
  margin-bottom: 280px;
}
</style>

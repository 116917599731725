<template>
  <section>
    <ContentCard data-auto-id="tokens-no-content" class="text-center">
      <div class="p-12">
        <img
          src="@/assets/empty-office.svg"
          alt="illustration of an empty office"
          class="inline-block mb-10"
        />
        <h3 class="text-sm mb-6 text-semibold">Create a Registration Token</h3>
        <p>
          Client Registration Tokens allow programatic creation of clients in
          your applications. You can use these tokens to create clients from
          mobile applications, web apps, and any other place where our SDK's are
          supported.
        </p>
        <p class="mb-10">
          There is no limit to the number of registration tokens you can have.
        </p>
        <TokenForm
          inline
          @submit="createToken"
          :loading="loading"
          :error="errorMessage"
          class="max-w-3/4 mx-auto"
        />
      </div>
    </ContentCard>
  </section>
</template>

<script>
import ContentCard from '../../../Common/ContentCard'
import TokenForm from './TokenForm'
export default {
  name: 'NoTokens',
  components: {
    ContentCard,
    TokenForm,
  },
  props: {
    createToken: Function,
    loading: Boolean,
    errorMessage: String,
  },
}
</script>

<template>
  <div class="home">
    <MainLayout>
      <div v-if="loading" class="text-center">
        <ToznyLoader class="mt-2 w-16 h-16 text-tozny" />
      </div>
      <ZeroStateLanding v-else-if="zeroState" />
      <div v-else>
        <h2 class="mb-4">Dashboard</h2>
        <ContentCard class="p-4">
          <div class="flex justify-between">
            <div class="flex items-center">
              <h3 class="font-semibold text-sm mr-8 mb-8">API Requests</h3>
              <p class="mb-8">
                <span class="font-bold text-gray-dark mr-2"
                  >Total Last 30 Days:</span
                >
                <span class="text-gray-dark">{{ totalApiCallsLast30 }}</span>
              </p>
            </div>
            <div>
              <div class="text-tiny text-gray-medium flex items-center">
                <div class="border-2 border-tozny w-6 mr-4"></div>
                Current Month
              </div>
              <div class="text-tiny text-gray-medium flex items-center">
                <div
                  class="border-2 border-dashed border-gray-medium w-6 mr-4"
                ></div>
                Previous Month
              </div>
            </div>
          </div>
          <div v-if="loadingMetrics" class="text-center">
            <ToznyLoader class="mt-2 w-8 h-8 text-tozny" />
          </div>
          <div
            v-if="metricsErrorMessage"
            id="form-error"
            class="mt-4 text-error text-tiny mb-4"
          >
            {{ metricsErrorMessage }}
          </div>
          <LineChart
            v-if="!loadingMetrics && !metricsErrorMessage"
            class="graphHeight"
            :chartData="datacollection"
          />
        </ContentCard>
        <ContentCard class="p-2" title="Request History">
          <template #extras>
            <ToznySwitch
              id="show-admin-toggle"
              label="Administrative Requests"
              :labelBefore="true"
              @input="toggleShowAdmin"
              :value="includeAdminRequests"
            />
          </template>
          <RequestHistoryTable
            class="pt-6 pb-8"
            :requests="requests"
            :hasMore="hasMoreRequests"
            :loading="loadingRequests"
            @loadMore="loadMore"
            :error="requestsErrorMessage"
          />
        </ContentCard>
      </div>
    </MainLayout>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import ContentCard from '@/Common/ContentCard'
import LineChart from './Components/LineChart'
import MainLayout from '../../Common/MainLayout/MainLayout'
import moment from 'moment'
import RequestHistoryTable from './Components/RequestHistoryTable'
import ToznyLoader from '@/Common/ToznyLoader'
import ToznySwitch from '@/Common/ToznySwitch'
import ZeroStateLanding from './Components/ZeroStateLanding'

export default {
  name: 'Dashboard',
  components: {
    ContentCard,
    LineChart,
    MainLayout,
    RequestHistoryTable,
    ToznyLoader,
    ToznySwitch,
    ZeroStateLanding,
  },
  computed: {
    ...mapState('clients', {
      clientsStatus: 'status',
      clientsInitialized: 'initialized',
    }),
    ...mapState('metrics', [
      'loadingRequests',
      'loadingMetrics',
      'includeAdminRequests',
      'requests',
      'currentMetrics',
      'previousMetrics',
      'requestsErrorMessage',
      'metricsErrorMessage',
    ]),
    ...mapGetters('metrics', ['hasMoreRequests']),
    loading() {
      return (
        this.clientsStatus === 'initializing' ||
        (this.clientsStatus === 'loading' && !this.clientsInitialized)
      )
    },
    zeroState() {
      return this.clientsStatus.split('.')[0] === 'zero'
    },
    metricsDates: function () {
      return this.currentMetrics.map((obj) => moment(obj.date).format('MMM DD'))
    },
    currentMetricsCounts: function () {
      return this.currentMetrics.map((obj) => obj.count)
    },
    previousMetricsCounts: function () {
      return this.previousMetrics.map((obj) => obj.count)
    },
    totalApiCallsLast30: function () {
      return this.currentMetrics
        .map((obj) => obj.count)
        .reduce((a, b) => a + b, 0)
    },
    datacollection: function () {
      return {
        // range of dates
        labels: this.metricsDates,
        datasets: [
          {
            label: 'Current Month',
            data: this.currentMetricsCounts,
            backgroundColor: 'transparent',
            borderColor: '#47B7AB',
            borderWidth: 4,
          },
          {
            label: 'Previous Month',
            backgroundColor: 'transparent',
            borderDash: [15, 5],
            borderColor: '#CBD0D5',
            borderWidth: 4,
            data: this.previousMetricsCounts,
          },
        ],
      }
    },
  },
  methods: {
    ...mapActions('clients', ['initialize']),
    ...mapActions('metrics', [
      'changeAdminRequests',
      'getRequests',
      'getMetrics',
    ]),

    toggleShowAdmin: async function (bool) {
      await this.changeAdminRequests(bool)
      await this.getRequests()
    },
    loadMore: async function () {
      await this.getRequests()
    },
  },

  created: async function () {
    await this.initialize()
    await this.getRequests()
    await this.getMetrics()
  },
}
</script>
<style scoped>
.graphHeight {
  height: 408px;
}
</style>

<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  name: 'LineChart',
  extends: Line,
  mixins: [reactiveProp],
  computed: {
    options: function () {
      return {
        legend: {
          display: false,
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                color: 'rgba(0, 0, 0, 0)',
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                color: 'rgba(0, 0, 0, 0)',
              },
            },
          ],
        },
        // styled, default tooltip
        tooltips: {
          backgroundColor: '#ffffff',
          bodyFontColor: 'black',
          mode: 'x',
          titleFontFamily: 'Open Sans',
          titleFontColor: '#A9B4BE',
          bodyFontFamily: 'Open Sans',
          borderColor: '#A9B4BE',
          borderWidth: 1,
          xPadding: 8,
          yPadding: 8,
          cornerRadius: 3,
          titleSpacing: 2,
          titleMarginBottom: 8,
          bodySpacing: 8,

          /*
              Generates a value for the difference from last month.
              If either data point is missing, returns undefined, and
              this value will not be rendered.  
          */
          callbacks: {
            afterBody: function (tooltipItem) {
              let diff
              if (tooltipItem[0] && tooltipItem[1]) {
                diff = tooltipItem[0]['yLabel'] - tooltipItem[1]['yLabel']
              }
              return diff
                ? `Difference from last month:  ${diff >= 0 ? '+' : ''}${diff}`
                : diff
            },
            labelColor: function (tooltipItem) {
              if (tooltipItem.datasetIndex === 0) {
                return {
                  borderColor: 'rgb(255, 255, 255)',
                  backgroundColor: '#47B7AB',
                }
              } else {
                return {
                  borderColor: 'rgb(255, 255, 255)',
                  backgroundColor: '#CBD0D5',
                }
              }
            },
            label: function (tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label || ''
              const value = tooltipItem.value
              return `  ${label} ${value}`
            },
          },
        },
      }
    },
  },

  mounted: function () {
    this.renderChart(this.chartData, this.options)
  },
}
</script>
<style scoped>
.chartjs-tooltip-key {
  border-radius: 5px;
  height: 5px;
  width: 5px;
}
</style>

<template>
  <section>
    <ContentCard data-auto-id="clients-no-content" class="text-center">
      <div class="p-12">
        <img
          v-if="disabled"
          src="@/assets/clients-disabled.svg"
          alt="illustration of two people"
          class="inline-block mb-10"
        />
        <img
          v-else
          src="@/assets/clients-enabled.svg"
          alt="illustration of two people"
          class="inline-block mb-10"
        />
        <h3
          :class="{ 'text-gray-medium': disabled }"
          class="text-sm mb-6 text-semibold"
        >
          Create a Client
        </h3>
        <p :class="{ 'text-gray-medium': disabled }" class="mb-10">
          Create clients below by entering a name and associating that client to
          a registration token. You can also create clients using any of our
          SDKs. Learn more by reading our API Documentation.
        </p>
        <ClientForm
          inline
          :tokens="tokens"
          :loading="loading"
          :error="errorMessage"
          class="text-left"
          :disabled="disabled"
          @submit="(e) => $emit('submit', e)"
        />
      </div>
    </ContentCard>
  </section>
</template>

<script>
import ContentCard from '@/Common/ContentCard'
import ClientForm from './ClientForm'
export default {
  name: 'NoClients',
  components: {
    ContentCard,
    ClientForm,
  },
  props: {
    createToken: Function,
    errorMessage: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tokens: Array,
  },
}
</script>
